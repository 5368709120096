import { IconButton } from '@material/mwc-icon-button';
import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property, query, queryAll } from 'lit/decorators.js'
import { toJS } from 'mobx';
import '@material/mwc-icon-button'
import { CONSTANTS } from '@@addons/constants';
import { wireframeSearchOptions } from '@@addons/functions/views/wireframe/search';
import { AutoCompleteSearch_I } from '@@addons/interfaces/view/wireframe/search';
import { wireframeProvider } from '@@addons/mobx-providers/wireframe';


@customElement("autocomplete-search-box")
export class AutocompleteSearchBox extends LitElement {
  constructor() { super(); }


  @property({ type: Boolean })
  private searchShowingMobile: boolean = false;

  @property({ type: String })
  private searchShowingMobileClassName: string = "showing";

  @property({ type: Array })
  private options: AutoCompleteSearch_I[] = [];

  connectedCallback() {
    super.connectedCallback();

    wireframeSearchOptions();

    this.options = wireframeProvider.autoCompleteSearch;
  }

  disconnectedCallback() { }
  static styles = [
    css`
   :host { display: block; }
  `
  ];

  render() {
    return html`
      <div autocomplete-search-box="search-box">
        <div autocomplete-search-box="container">
          <input type="search" placeholder="Search..." autocomplete="on" autocomplete-search-box="input" />
          <mwc-icon autocomplete-search-box="icon">search</mwc-icon>
        </div>
      </div>
      <main class="main">
        <ul autocomplete-search-box="ul" scrollbar="app-scrollbar"></ul>
      </main>
      <mwc-icon-button icon="search" class="inactive" autocomplete-search-box="button"></mwc-icon-button>
    `;
  }

  firstUpdated() {
    window.addEventListener('resize', (e) => {
      this.requestUpdate();
      // this.requestFullscreen
    });
    const autoCompleteBoxes: NodeListOf<Element> = document.querySelectorAll('autocomplete-search-box');
    autoCompleteBoxes.forEach(autoCompleteBox => {
      const autoCompleteField: HTMLInputElement = autoCompleteBox.querySelector('[autocomplete-search-box="input"]'),
        resultsHTML: HTMLUListElement = autoCompleteBox.querySelector('[autocomplete-search-box="ul"]'),
        toggleButton: IconButton = autoCompleteBox.querySelector('[autocomplete-search-box="button"]'),
        searchBox: Element = autoCompleteBox.querySelector('[autocomplete-search-box="search-box"]');
      autoCompleteField.addEventListener('input', (e) => {
        // @ts-ignore 
        let value: string = e.target.value.toLocaleLowerCase();
        // console.log({ "this": this, value, e });

        if (value.length > 0) {
          let results: AutoCompleteSearch_I[] = this.getResults(value);
          this.showResults(resultsHTML, results);
          // console.log({ "....results//..": results });
        } else {
          this.removeResults(resultsHTML);
        }
      });
      autoCompleteField.addEventListener('focus', (f: FocusEvent) => {
        // @ts-ignore 
        let value: string = f.target.value.toLocaleLowerCase();
        // console.log({ "this": this, value, f });

        if (value.length > 0) {
          let results: AutoCompleteSearch_I[] = this.getResults(value);
          this.showResults(resultsHTML, results);
        }

      });
      toggleButton.addEventListener('click', (e) => {
        if (this.searchShowingMobile) {
          if (searchBox.classList.contains(this.searchShowingMobileClassName)) {
            searchBox.classList.remove(this.searchShowingMobileClassName);
            this.searchShowingMobile = false;
          }
        } else {
          searchBox.classList.add(this.searchShowingMobileClassName);
          this.searchShowingMobile = true;
        }
      });
      document.addEventListener('click', (e) => {
        if ((e.target !== autoCompleteBox)
          && (e.target !== autoCompleteField)
          && (e.target !== resultsHTML)
          && (e.target !== toggleButton)) {
          this.removeResults(resultsHTML);

          if (this.searchShowingMobile) {
            // console.log({"this.searchShowingMobile": this.searchShowingMobile});
            
            if (searchBox.classList.contains(this.searchShowingMobileClassName)) {
              // console.log({"contains(this.searchShowingMobileClassName)": searchBox.classList.contains(this.searchShowingMobileClassName)});
              searchBox.classList.remove(this.searchShowingMobileClassName);
              this.searchShowingMobile = false;
            }
          }
        }
      });
    });
  }

  private getResults(inputValue: string) {
    let results: AutoCompleteSearch_I[] = [];

    const options = toJS(this.options)
    // console.log({ "....this.options//..": options });
    for (let o = 0; o < options.length; o++) {
      const option = options[o];
      const tags = option.tags;
      // console.log({ tags, "option.title": option.title, option });

      for (let i = 0; i < tags.length; i++) {
        let tag = tags[i].toLocaleLowerCase();
        let desc = `tag~${option.title}`
        // console.log({
        //   desc, tag, "tag.indexOf(inputValue)": tag.indexOf(inputValue)
        // });
        if (tag.indexOf(inputValue) > -1) {
          if (!results.includes(option)) {
            results.push(option);
          }
        }
      }
    }
    return results;
  }

  private showResults(resultsHTML: HTMLUListElement, results: AutoCompleteSearch_I[]) {
    let renderedResults = "";
    results.forEach(result => {
      let resultItem = this.resultItem(result);
      renderedResults += resultItem;
    });
    return resultsHTML.innerHTML = renderedResults;
  }

  private resultItem(result: AutoCompleteSearch_I): string {
    let resultBaseUrl = result.baseUrl;
    if ((result.baseUrl.indexOf("http://") > -1)
      || (result.baseUrl.indexOf("https://") > -1)) {
      resultBaseUrl = resultBaseUrl;
    } else {
      resultBaseUrl = resultBaseUrl[0] === "/"
        ? resultBaseUrl.slice(1, (resultBaseUrl.length + 1)) : resultBaseUrl
      resultBaseUrl = CONSTANTS.URLS.HOME_PAGE_BASE_URL + resultBaseUrl;
    }
    // console.log({ resultBaseUrl });

    return `
      <li class="li">
        <a href="${resultBaseUrl}" class="a">
          <span class="icon">
            <mwc-icon>${result.icon}</mwc-icon>
          </span>
          <span class="content">
            <h5 class="title">${result.title}</h5>
            <p class="description">${result.description}</p>
          </span>
        </a>
      </li>
    `;
  }

  private removeResults(resultsHTML: HTMLUListElement) {
    resultsHTML.innerHTML = "";
  }

  createRenderRoot() {
    return this;
  }
}

import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../assets/styles/views/widget/alert/alert.scss';


@customElement('alert-card')
export class AlertCard extends LitElement {
  constructor() { super(); }

  @property({ type: Boolean })
  public success?: boolean = false;

  @property({ type: Boolean })
  public info?: boolean = false;

  @property({ type: Boolean })
  public warning?: boolean = false;

  @property({ type: Boolean })
  public danger?: boolean = false;

  @property({ type: Boolean })
  public default?: boolean = false;

  @property({ type: Boolean })
  public primary?: boolean = false;

  public header?: TemplateResult<1>;

  public content?: TemplateResult<1>;

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() { }
  static styles = [
    css`
   :host { display: block; }
  `
  ];

  render() {
    return html`
      <div class="alert-box">
        <div class="alert-container ${this.getClass}">
          <aside class="${this.getClass}">
            <div class="padded">
              ${this.getIcon}
            </div>
          </aside>
          <div class="content">
            <header class="header">
              ${this.header}
            </header>
            <main class="main">
              ${this.content}
            </main>
          </div>
        </div>
      </div>
    `;
  }

  private get getClass() {
    if (this.success) {
      return "success";
    }
    if (this.info) {
      return "info";
    }
    if (this.warning) {
      return "warning";
    }
    if (this.danger) {
      return "danger";
    }
    if (this.default) {
      return html`<mwc-icon></mwc-icon>`;
    }
    if (this.primary) {
      return "primary";
    }
  }

  private get getIcon() {
    if (this.success) {
      return html`<mwc-icon>check_circle_outline</mwc-icon>`;
    }
    if (this.info) {
      return html`<mwc-icon>notification_important</mwc-icon>`;
    }
    if (this.warning) {
      return html`<mwc-icon>warning</mwc-icon>`;
    }
    if (this.danger) {
      return html`<mwc-icon>error_outline</mwc-icon>`;
    }
    if (this.default) {
      return html`<mwc-icon>priority_high</mwc-icon>`;
    }
    if (this.primary) {
      return html`<mwc-icon>star_outline</mwc-icon>`;
    }

    return html`<mwc-icon>emoji_objects</mwc-icon>`;
  }

  firstUpdated() { }

  createRenderRoot() {
    return this;
  }
}

import { MDCDrawer } from "@material/drawer";

import { LitElement, html, css } from 'lit';
import { customElement, property, query, queryAll } from 'lit/decorators.js'
import { MDCTopAppBar } from "@material/top-app-bar";
import "../buttons/link-buttons/nav-button";
import "../../../assets/styles/views/wireframe/main.scss";
import '@material/mwc-ripple';
import "./right_drawer_content";

@customElement('right-side-bar-drawer')
export class RightSideBarDrawer extends LitElement {
  constructor() { super(); }

  @property({ type: Object })
  private _property?: any;

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() { }
  static styles = [
    css`
   :host { display: block; }
  `
  ];

  render() {
    return html`
    <aside class="mdc-drawer mdc-drawer--modal z-20 !important" id="right-mdc-drawer">
      <main class="right-drawer-content" scrollbar="app-scrollbar">
        <right-drawer-content></right-drawer-content>
      </main>
    </aside>
    <div class="mdc-drawer-scrim"></div>`;
  }

  firstUpdated() {
    const drawer = MDCDrawer.attachTo(document.querySelector('#right-mdc-drawer'));

    const topAppBar = MDCTopAppBar.attachTo(document.getElementById('rightButton-drawer'));
    topAppBar.setScrollTarget(document.getElementById('main-content'));
    topAppBar.listen('MDCTopAppBar:nav', (e) => {
      // console.log({ 'rightButton-drawer': e });

      drawer.open = !drawer.open;
    });
  }

  createRenderRoot() {
    return this;
  }
}

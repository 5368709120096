// import { MDCList } from "@material/list";

import { MDCDrawer } from "@material/drawer";

import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property, query, queryAll } from 'lit/decorators.js'
import { MDCTopAppBar } from "@material/top-app-bar";
import "../buttons/link-buttons/nav-button";
import "../../../assets/styles/views/wireframe/main.scss";
import '@material/mwc-ripple';
import './profile_avatar';
import "@@addons/widgets/alert";
import '@material/mwc-circular-progress';
import { SideBarMenuItem_I } from "@@addons/interfaces/sidebar/menu-item";
import { wireframeProvider } from "@@addons/mobx-providers/wireframe";
import { LoginStore_I } from "@@addons/interfaces/database/login";
import { PublicLoginStore_I } from "@@addons/interfaces/database/login/public";
import { CONSTANTS } from "@@addons/constants";
import { LoginStore } from "@@addons/database/dixie/login";

@customElement('side-bar-drawer')
export class SideBarDrawer extends LitElement {
  constructor() { super(); }

  @property({ type: Array })
  public menuItems: SideBarMenuItem_I[] = [];

  @property({ attribute: false })
  public menuItemsList: any;

  @property({ type: Array })
  private bioData: LoginStore_I[] | PublicLoginStore_I[] = [];

  @property({ type: Boolean })
  private bioDataGetting: boolean = false;
  
  loginDb = new LoginStore();

  async connectedCallback() {
    super.connectedCallback();

    this.menuItems = wireframeProvider.menuItems;

    this.bioDataGetting = true;
    await this.loginDb.getAll((response) => {
      // console.log({response});
      this.bioData = response;
      this.bioDataGetting = false;
    });

    // console.log({"this._menuItems": this.menuItems});
    
  }

  disconnectedCallback() { }
  static styles = [
    css`
   :host { display: block; }
  `
  ];

  render() {
    return html`
    <aside class="mdc-drawer mdc-drawer--modal">
      <!-- <div class="mdc-drawer__header">
        <h3 class="mdc-drawer__title">Mail</h3>
        <h6 class="mdc-drawer__subtitle">email@material.io</h6><profile-avatar class="flex justify-center w-full"></profile-avatar>
      </div> -->
      ${this.bioContent}
      <div class="mdc-drawer__content">
        <div class="mdc-deprecated-list mobile-nav" id="app-scrollbar">
          <li class="px-5">
            <div class="flex flex-row items-center h-8">
              <div class="text-sm font-light tracking-wide text-gray-500">Menu</div>
            </div>
          </li>
          ${this.menuItemsList}
        </div>
      </div>
    </aside>
    <div class="mdc-drawer-scrim"></div>`;
  }

  private get bioContent() {
    return CONSTANTS.APP_SETTINGS.isClientApp
      ? this.clientBioContent : this.publicBioContent;
  }

  private get clientBioContent() {
    if (this.bioDataGetting == false) {
      if (this.bioData.length > 0) {
        const bioData: LoginStore_I = this.bioData[0];

        return html`
          <div class="mdc-drawer__header">
            <h3 class="mdc-drawer__title">${bioData.firstname} ${bioData.middleName} ${bioData.surname}</h3>
            <h6 class="mdc-drawer__subtitle"><a href="mailto:${bioData.email}">${bioData.email}</a></h6>
            <h6 class="mdc-drawer__subtitle mb-2"><a href="tel:${bioData.phone}">${bioData.phone}</a></h6>
            <hr/>
            <profile-avatar .bioData="${this.bioData}" class="mt-2 flex justify-center w-full"></profile-avatar>
          </div>
        `;
      } else {
        return html`
          <div class="mdc-drawer__header">
            <alert-card info .header="${html`Empty`}" .content="${html`No Data Found`}"></alert-card>
          </div>
        `;
      }
    } else {
      return html`
        <div class="mdc-drawer__header">
          <div class="flex justify-center">
            <mwc-circular-progress indeterminate></mwc-circular-progress>
          </div>
        </div>
      `;
    }
  }

  private get publicBioContent() {
    if (this.bioDataGetting == false) {
      if (this.bioData.length > 0) {
        const bioData: PublicLoginStore_I = this.bioData[0];
        if (bioData.accountType === 1) {
          return html`
            <div class="mdc-drawer__header">
              <h3 class="mdc-drawer__title">${bioData.firstname} ${bioData.middlename} ${bioData.lastname}</h3>
              <h6 class="mdc-drawer__subtitle"><a href="mailto:${bioData.email}">${bioData.email}</a></h6>
              <h6 class="mdc-drawer__subtitle mb-2"><a href="tel:${bioData.phone}">${bioData.phone}</a></h6>
              <hr/>
              <profile-avatar .bioData="${this.bioData}" class="mt-2 flex justify-center w-full"></profile-avatar>
            </div>
          `;
        } else {
          return html`
            <div class="mdc-drawer__header">
              <h3 class="mdc-drawer__title">${bioData.organizationName}</h3>
              <h6 class="mdc-drawer__subtitle"><b>${bioData.contactPersonName}</b></h6>
              <h6 class="mdc-drawer__subtitle"><a href="mailto:${bioData.contactPersonEmail}">${bioData.contactPersonEmail}</a></h6>
              <h6 class="mdc-drawer__subtitle mb-2"><a href="tel:${bioData.contactPersonPhone}">${bioData.contactPersonPhone}</a></h6>
              <hr/>
              <profile-avatar .bioData="${this.bioData}" class="mt-2 flex justify-center w-full"></profile-avatar>
            </div>
          `;
        }
      } else {
        return html`
          <div class="mdc-drawer__header">
            <alert-card info .header="${html`Empty`}" .content="${html`No Data Found`}"></alert-card>
          </div>
        `;
      }
    } else {
      return html`
        <div class="mdc-drawer__header">
          <div class="flex justify-center">
            <mwc-circular-progress indeterminate></mwc-circular-progress>
          </div>
        </div>
      `;
    }
  }

  firstUpdated() {
    // const listEl = document.querySelector('.mdc-drawer .mdc-deprecated-list');
    // const list = MDCList.attachTo(listEl);
    // list.wrapFocus = true;

    const drawer = MDCDrawer.attachTo(document.querySelector('.mdc-drawer'));

    // const mainContentEl = document.querySelector('.main-content');

    // // listEl.addEventListener('click', (event) => {
    // //   drawer.open = false;
    // // });

    // document.body.addEventListener('MDCDrawer:closed', () => {
    //   // mainContentEl.querySelector<any>('input, button').focus();
    // });
    const topAppBar = MDCTopAppBar.attachTo(document.getElementById('app-bar'));
    topAppBar.setScrollTarget(document.getElementById('main-content'));
    topAppBar.listen('MDCTopAppBar:nav', () => {
      drawer.open = !drawer.open;
    });
    // mainContentEl.addEventListener('click', (e) => {
    //   e.preventDefault();
    //   // console.log({ e });

    //   drawer.open = false;
    // })
  }

  createRenderRoot() {
    return this;
  }
}

import { LoginStore_I } from "@@addons/interfaces/database/login";
import { PublicLoginStore_I } from "@@addons/interfaces/database/login/public";
import { LoginProfileProvider } from "@@addons/mobx-providers/login";
import { toJS } from "mobx";

export function GetUserPageFx(
  userProfile: LoginStore_I | PublicLoginStore_I,
  loginProfileProvider: LoginProfileProvider,
  callback: (arg0: LoginStore_I) => void) {
  if (userProfile.id === undefined) {
    callback(toJS(loginProfileProvider.profile));
  }
}
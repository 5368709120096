import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js'

@customElement('nav-link-button')
export class NavLinkButton extends LitElement {

  @property({ type: String })
  private aClass?: string;

  @property({ type: String })
  private bClass?: string;

  @property({ type: String })
  private icon?: string;

  @property({ type: String })
  private href?: string;

  @property({ type: String })
  public label?: string;

  render() {
    return html`
      <a class="mdc-deprecated-list-item my-4 ${this.aClass}" href="${this.href}">
        <mwc-button class="mdc-deprecated-list-item__ripple mt-1 text-left ${this.bClass}" icon="${this.icon}"
          label="${this.label}" ripple="true" expandContent="true">
        </mwc-button>
      </a>
    `;
  }

  createRenderRoot() {
    return this;
  }
}

import { MobxLitElement } from '@adobe/lit-mobx';
import { html, css } from 'lit';
import { customElement, property, } from 'lit/decorators.js'
import './profile_avatar';
import '@material/mwc-button';
import "@@addons/widgets/alert";
import '@material/mwc-circular-progress';
import { CONSTANTS } from '@@addons/constants';
import "@@addons/widgets/buttons/link-buttons/link-button";
import { LoginStore } from '@@addons/database/dixie/login';
import { LoginStore_I } from '@@addons/interfaces/database/login';
import { PublicLoginStore_I } from '@@addons/interfaces/database/login/public';


@customElement('right-drawer-content')
export class RightDrawerContent extends MobxLitElement {
  constructor() { super(); }


  @property({ type: Object })
  private _property?: any;

  @property({ type: Array })
  private bioData: LoginStore_I[] | PublicLoginStore_I[] = [];

  @property({ type: Boolean })
  private bioDataGetting: boolean = false;
  
  loginDb = new LoginStore();

  async connectedCallback() {
    super.connectedCallback();

    this.bioDataGetting = true;
    await this.loginDb.getAll((response) => {
      // console.log({response});
      this.bioData = response;
      this.bioDataGetting = false;
    });
  }

  disconnectedCallback() { }
  static styles = [
    css`
   :host { display: block; }
  `
  ];

  render() {
    return html`
      <profile-avatar .bioData="${this.bioData}"></profile-avatar>
      <div class="bio-data">
        ${this.bioContent}
      </div>
      ${this.UpdateProfileBtn}
    `;
  }

  private get UpdateProfileBtn() {
    if (CONSTANTS.APP_SETTINGS.isClientApp) {
      return html`<mwc-button class="gray" label="Update Profile"></mwc-button>`;
    } else {
      return html`<link-button raised href="${CONSTANTS.URLS.PUBLIC_PAGE_BASE_URL}update-profile" 
        bClass="default" label="Update Profile"></link-button>`;
    }
  }

  private get bioContent() {
    return CONSTANTS.APP_SETTINGS.isClientApp
      ? this.clientBioContent : this.publicBioContent;
  }

  private get clientBioContent() {
    if (this.bioDataGetting == false) {
      if (this.bioData.length > 0) {
        const bioData: LoginStore_I = this.bioData[0];

        return html`
          <div class="bio-content">
            <h4 class="name">${bioData.firstname} ${bioData.middleName} ${bioData.surname}</h4>
            <h6 class="email">
              <a href="mailto:${bioData.email}">${bioData.email}</a>
            </h6>
            <h6 class="phone">
              <a href="tel:${bioData.phone}">${bioData.phone}</a>
            </h6>
          </div>
        `;
      } else {
        return html`
          <div class="main-container">
            <alert-card info .header="${html`Empty`}" .content="${html`No Data Found`}"></alert-card>
          </div>
        `;
      }
    } else {
      return html`
        <div class="main-container">
          <div class="flex justify-center">
            <mwc-circular-progress indeterminate></mwc-circular-progress>
          </div>
        </div>
      `;
    }
  }

  private get publicBioContent() {
    if (this.bioDataGetting == false) {
      if (this.bioData.length > 0) {
        const bioData: PublicLoginStore_I = this.bioData[0];
        if (bioData.accountType === 1) {
          return html`
            <div class="bio-content">
              <h4 class="name">${bioData.firstname} ${bioData.middlename} ${bioData.lastname}</h4>
              <h6 class="email">
                <a href="mailto:${bioData.email}">${bioData.email}</a>
              </h6>
              <h6 class="phone">
                <a href="tel:${bioData.phone}">${bioData.phone}</a>
              </h6>
            </div>
          `;
        } else {
          return html`
            <div class="bio-content">
              <h4 class="org-name">${bioData.organizationName}</h4>
              <h4 class="name">${bioData.contactPersonName}</h4>
              <h6 class="email">
                <a href="mailto:${bioData.contactPersonEmail}">${bioData.contactPersonEmail}</a>
              </h6>
              <h6 class="phone">
                <a href="tel:${bioData.contactPersonPhone}">${bioData.contactPersonPhone}</a>
              </h6>
            </div>
          `;
        }
      } else {
        return html`
          <div class="main-container">
            <alert-card info .header="${html`Empty`}" .content="${html`No Data Found`}"></alert-card>
          </div>
        `;
      }
    } else {
      return html`
        <div class="main-container">
          <div class="flex justify-center">
            <mwc-circular-progress indeterminate></mwc-circular-progress>
          </div>
        </div>
      `;
    }
  }

  firstUpdated() { }

  createRenderRoot() {
    return this;
  }
}

import { CONSTANTS } from "@@addons/constants";
import { SideBarMenuItem_I } from "@@addons/interfaces/sidebar/menu-item";
import { html, TemplateResult } from "lit";

export const listItem = (params: SideBarMenuItem_I, btnAction: () => void = null): TemplateResult => {

  if (btnAction === null) {
    const BaseUrl = CONSTANTS.APP_SETTINGS.isClientApp
      ? CONSTANTS.URLS.HOME_PAGE_BASE_URL : CONSTANTS.URLS.PUBLIC_PAGE_BASE_URL;
    let paramsBaseUrl = params.baseUrl;
    // console.log({paramsBaseUrl});

    if ((params.baseUrl.indexOf("http://") > -1)
      || (params.baseUrl.indexOf("https://") > -1)) {
      paramsBaseUrl = paramsBaseUrl;
    } else {
      paramsBaseUrl = paramsBaseUrl[0] === "/"
        ? paramsBaseUrl.slice(1, (paramsBaseUrl.length + 1)) : paramsBaseUrl;
      // paramsBaseUrl = CONSTANTS.URLS.HOME_PAGE_BASE_URL + paramsBaseUrl;
      paramsBaseUrl = BaseUrl + paramsBaseUrl;
    }
    // console.log({ paramsBaseUrl });

    return html`
      <li class="${params.listClass}">
        <a href="${paramsBaseUrl}" class="a ${params.active == true ? " selected" : ""}">
          <span class="icon">
            <mwc-icon>${params.icon}</mwc-icon>
          </span>
          <span class="title">${params.title}</span>
        </a>
      </li>
    `;
  } else {
    return html`
      <li class="${params.listClass}">
        <a href="" class="a ${params.active == true ? " selected" : ""}" @click="${_btnAction}">
          <span class="icon">
            <mwc-icon>${params.icon}</mwc-icon>
          </span>
          <span class="title">${params.title}</span>
        </a>
      </li>
    `;
    function _btnAction(e: any) {
      e.preventDefault();
      btnAction();
    }
  }
}
import { AppMenuStore } from "@@addons/database/dixie/app_menus";
import { AutoCompleteSearch_I } from "@@addons/interfaces/view/wireframe/search";
import { wireframeProvider } from "@@addons/mobx-providers/wireframe";

export function wireframeSearchOptions() {

  const appMenuStore = new AppMenuStore();

  appMenuStore.getAll((gaRes) => {
    // console.log({ gaRes });

    let menuItems: AutoCompleteSearch_I[] = [];

    gaRes.forEach(menu => {
      // console.log({ "---menu---": menu });
      const autoCompleteSearch: AutoCompleteSearch_I = {
        baseUrl: menu.baseUrl,
        description: menu.module,
        icon: menu.icon,
        tags: menu.tags,
        title: menu.title,
      };
      if (!menuItems.includes(autoCompleteSearch)) {
        menuItems.push(autoCompleteSearch);
      }
    });
    wireframeProvider.setAutoCompleteSearch(menuItems);
    // console.log({ "---menuItems1---": menuItems });
  });
}
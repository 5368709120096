import { http } from "@@addons/functions/https/http";
import { https_swal_error_format } from "@@addons/functions/https/https_swal_error_format";
import { ApDbCallback_I } from "@@addons/interfaces/database";
import { OfflinePostStore_I } from "@@addons/interfaces/database/offline_post";
import { ServiceWorkerMessenger } from "@@addons/service-worker/messenger";
import { OfflinePostStore } from ".";

export class SaveIfOnline {
  private offlinePost = new OfflinePostStore();
  constructor() {
    setInterval(() => {
      this.init();
    }, 120000);
  }

  private init() {
    const swMessenger = new ServiceWorkerMessenger();
    swMessenger.networkStatus(async (status) => {
      // console.log({ "networkStatus": status });
      if (status === true) {
        await this.getAll();
      }
    });
  }

  private async getAll() {
    return await this.offlinePost.getAll((response) => {
      console.log({ "getAll-response": response });
      this.post(response);
    }
    );
  }

  public post(entries: OfflinePostStore_I[],
    callback?: ApDbCallback_I<true|string>) {
    entries.forEach(async entry => {
      if (entry.networkResponse === "") {
        await http(entry.url, {
          method: "POST",
          body: entry.formData,
        }, true, true).then(async (response: any) => {
          // console.log("saveIfOnline: ", {response});
          
          if (response.success) {

            await this.remove(entry);
            callback(true);
          } else {
            const res = https_swal_error_format(response.msg, false);
            await this.update(entry, res);
            callback(res);
          }
        }).catch(error => {
          // console.log({ error });
        });
      } else {
        callback(entry.networkResponse);
      }
    });
  }

  private async update(entry: OfflinePostStore_I, response: string) {
    // console.log({ "updating": entry });

    let newData: OfflinePostStore_I = entry;
    newData.networkResponse = response;
    const offlinePost = new OfflinePostStore();
    // await this.offlinePost.doUpdate(entry.id, newData,
    await offlinePost.doUpdate(entry.id, newData,
      (response) => {
        // console.log({ "update-response": response });
      },
    );
  }

  private async remove(entry: OfflinePostStore_I) {
    // console.log({ "removing": entry });

    const offlinePost = new OfflinePostStore();
    // await this.offlinePost.removeFrom(entry.id,
    await offlinePost.removeFrom(entry.id,
      (response) => {
        // console.log({ "remove-response": response });
      },
    );
  }
}
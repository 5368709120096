import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js'


@customElement('akwaaba-app-clock')
export class AkwaabaAppClock extends LitElement {
  constructor() { super(); }

  @property({ type: String })
  private time: string = "0:0 AM";

  connectedCallback() {
    super.connectedCallback();
    this.startClock();
  }

  render() {
    return html`
      <div class="container">
        <h1 class="time">
          ${this.time}
        </h1>
      </div>
    `;
  }

  firstUpdated() {
  }

  private startClock() {
    const today: Date = new Date();
    let h: number = today.getHours(),
      m: number | string = today.getMinutes(),
      meridiem: string = h >= 12 ? "PM" : "AM";

    h = this.checkHour(h);
    m = this.checkMinute(m);
    this.time = h + ":" + m + " " + meridiem;
    // console.log({ "this.time": this.time });

    setTimeout(() => this.startClock(), 1000);
  }

  private checkHour(i: number): number {
    if (i === 0) {
      return 0
    } else if (i > 12) {
      return i - 12;
    }
    return i;
  }

  private checkMinute(i: number): string {
    let newI: string = String(i)
    if (i < 10) {
      newI = "0" + i;
    }
    return newI;
  }

  createRenderRoot() {
    return this;
  }
}

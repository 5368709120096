import { LoginStore_I } from '@@addons/interfaces/database/login';
import { action, makeObservable, observable, toJS } from 'mobx';

// create a mobx observable
export class LoginProfileProvider {

  public profile: LoginStore_I = {};
  public setProfile(value: LoginStore_I) {
    // console.log({"value: LoginStore_I": value});
    
    this.profile = value;
    // console.log({"this.profile": toJS(this.profile)});
  }

  public constructor() {
    makeObservable(this, {
      setProfile: action,
      profile: observable,
    });
  }
}

// create instance that can be shared across components
export let loginProfileProvider = new LoginProfileProvider();

import { MobxLitElement } from '@adobe/lit-mobx';
import { html, css, TemplateResult } from 'lit';
import { customElement, property, query, queryAll } from 'lit/decorators.js'
import './drawer';
import '@material/mwc-icon';
import '@material/mwc-list';
import "../buttons/link-buttons/nav-button";
import "../../../assets/styles/views/wireframe/main.scss";
import './right-drawer';
import { CONSTANTS } from '@@addons/constants';
import { __activePage } from '@@addons/functions/views/sidebar/active_page';
import { listItem } from '@@addons/functions/views/sidebar/list_item/index.';
import { generateMenuItems } from '@@addons/functions/views/sidebar/main';
import { wireframeAppMenuStore } from '@@addons/functions/views/wireframe/app_store';
import { SideBarMenuItem_I } from '@@addons/interfaces/sidebar/menu-item';
import { wireframeProvider } from '@@addons/mobx-providers/wireframe';
import { LoginStore } from '@@addons/database/dixie/login';
import Swal from 'sweetalert2';
import { SystemAppPageOFFLINE_ACTIVITIES } from '@@addons/constants/public_pages';


@customElement("side-bar")
export class SideBarComponent extends MobxLitElement {
  constructor() { super(); }

  //  @query(identifier)
  //  private _identifier?: any;

  //  @queryAll(identifier)
  //  private _identifier?: any;

  @property({ type: String })
  private _BaseUrl: string = CONSTANTS.APP_SETTINGS.isClientApp
    ? CONSTANTS.URLS.HOME_PAGE_BASE_URL: CONSTANTS.URLS.PUBLIC_PAGE_BASE_URL;

  @property({ type: Array })
  private _menuItems: SideBarMenuItem_I[] = [];

  smallDevice = window.matchMedia('(max-width: 768px)');
  mediumDevice = window.matchMedia('(max-width: 1280px)');

  connectedCallback() {
    super.connectedCallback();
    
    wireframeAppMenuStore(wireframeProvider);
    this._menuItems = wireframeProvider.menuItems;

    // console.log({"this._menuItems": this._menuItems});
    

    wireframeProvider.setisSmallDevice(this.smallDevice.matches);
    wireframeProvider.setisMediumDevice(this.mediumDevice.matches);
  }

  disconnectedCallback() { }
  static styles = [
    css`
    :host { display: block; }
    `
  ];

  render() {
    return this.sideBarType();
  }

  firstUpdated() {
    window.addEventListener('resize', (e) => {
      // console.log({
      //   "smallDevice.media": this.smallDevice.media,
      //   "smallDevice.match": this.smallDevice.matches,
      //   "mediumDevice.media": this.mediumDevice.media,
      //   "mediumDevice.match": this.mediumDevice.matches,
      // });
      wireframeProvider.setisSmallDevice(this.smallDevice.matches);
      wireframeProvider.setisMediumDevice(this.mediumDevice.matches);
    });
  }
  
  private sideBarType(): TemplateResult {
    if (wireframeProvider.isSmallDevice) {
      return html`<side-bar-drawer .menuItemsList="${this.lists()}"></side-bar-drawer>`;
    } else {
      let rightSideBarDrawer = html``;
      if (wireframeProvider.isMediumDevice && !wireframeProvider.isSmallDevice) {
        rightSideBarDrawer = html`<right-side-bar-drawer></right-side-bar-drawer>`;
      }
      return html`
        ${rightSideBarDrawer} ${this.sideBarMain()}
      `;
    }
  }

  private sideBarMain(): TemplateResult {
    return html`
      <nav class="nav">
        <div>
          <header class="header">
            <div>Akwaaba Forms</div>
          </header>
          <main class="main" scrollbar="app-scrollbar">
            <ul class="ul">
              <li class="px-5">
                <div class="flex flex-row items-center h-8">
                  <div class="text-sm font-light tracking-wide text-gray-500">Menu</div>
                </div>
              </li>
              ${this.lists()}
            </ul>
          </main>
        </div>
      </nav>
      `;
  }

  private lists(): TemplateResult {
    return html`
      ${listItem({
        active: __activePage(0), 
        baseUrl: `${this._BaseUrl}`, 
        icon: "home", 
        listClass: "", 
        title: "Dashboard"
      })}
      ${generateMenuItems(this._menuItems)}
      ${listItem({
        active: __activePage(SystemAppPageOFFLINE_ACTIVITIES), 
        baseUrl: `${this._BaseUrl}system/offline-activities`, 
        icon: "network_checks", 
        listClass: "", 
        title: "Offline Activities"
      })}
      ${listItem({
        active: false, 
        baseUrl: `${this._BaseUrl}system/logout`, 
        icon: "logout", 
        listClass: "", 
        title: "Logout",
      }, () => this.logout())}
    `;
  }

  private async logout() {
    return Swal.fire({
      title: 'Do you want to logout?',
      icon: 'info',
      iconColor: '#f97817',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      confirmButtonColor: '#f97817',
      cancelButtonColor: '#ddd',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      backdrop: true,
      preConfirm: async (logout) => {
        const appStore = new LoginStore();
        await appStore.removeAll();
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loging Out...",
          icon: 'success',
          iconColor: '#f97817',
          confirmButtonColor: '#f97817',
        });
        window.location.href = CONSTANTS.APP_SETTINGS.isClientApp
          ? CONSTANTS.URLS.LOGIN_PAGE_BASE_URL
          : CONSTANTS.URLS.PUBLIC_PAGE_BASE_URL + "login";
      }
    });
  }

  createRenderRoot() {
    return this;
  }
}

import { CONSTANTS } from '@@addons/constants';
import { LoginStore_I } from '@@addons/interfaces/database/login';
import { PublicLoginStore_I } from '@@addons/interfaces/database/login/public';
import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js'


@customElement('profile-avatar')
export class ProfileAvatar extends LitElement {
  constructor() { super(); }

  @property({ type: Object })
  private _property?: any;

  @property({ type: Array })
  private bioData: LoginStore_I[] | PublicLoginStore_I[];

  connectedCallback() {
    super.connectedCallback();
    
  }

  disconnectedCallback() { }
  static styles = [
    css`
   :host { display: block; }
  `
  ];

  render() {
    return html`
    <div class="h-48 w-48 rounded-full shadow-inner border-2 border-solid flex items-center justify-center">
      <div
        class="h-40 w-40 bg-gradient-to-tr from-akwaaba-orange-default via-white to-akwaaba-orange-dark rounded-full flex items-center justify-center">
        <div class="h-36 w-36 bg-white rounded-full flex items-center justify-center shadow-inner">
          ${this.getImage}
        </div>
      </div>
    </div>
  `;
  }

  private get getImage() {
    if (!Array.isArray(this.bioData)) {
      // return html`<h5>No Profile Picture Found</h5>`;
      if (CONSTANTS.APP_SETTINGS.isClientApp) {
        return html`<img
          class="object-cover h-full w-full rounded-full shadow-inner"
          src="${CONSTANTS.URLS.FILE_BUCKET_BASE_URL}files/logo.png">`;
      } else {
        return html`<img
          class="object-cover h-full w-full rounded-full shadow-inner"
          src="${CONSTANTS.URLS.FILE_BUCKET_BASE_URL}files/akwaaba_forms/member/profile/member.png">`;
      }
    }
    if (this.bioData.length > 0) {
      if (CONSTANTS.APP_SETTINGS.isClientApp) {
        const BioData: LoginStore_I = this.bioData[0];
        return html`<img
          class="object-cover h-full w-full rounded-full shadow-inner"
          src="${CONSTANTS.URLS.FILE_BUCKET_BASE_URL}files/clients/profile-picture/${BioData.profilePicture}">`;
      } else {
        const BioData: PublicLoginStore_I = this.bioData[0],
          image = BioData.accountType === 1 ? BioData.profilePhoto : BioData.logo;
        // console.log({BioData, image});
        
        return html`<img
          class="object-cover h-full w-full rounded-full shadow-inner"
          src="${CONSTANTS.URLS.FILE_BUCKET_BASE_URL}files/akwaaba_forms/${image}">`;
      }
    } else {
      return html`<h5>No Profile Picture Found</h5>`;
    }
  }

  firstUpdated() { }

  createRenderRoot() {
    return this;
  }
}

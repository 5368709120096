import { AppMenuStore } from "@@addons/database/dixie/app_menus";
import { SideBarMenuItem_I } from "@@addons/interfaces/sidebar/menu-item";
import { WireframeProvider } from "@@addons/mobx-providers/wireframe";
import { __activePage } from "../../sidebar/active_page";


function getAndSet(wireframeProvider: WireframeProvider) {
  const appMenuStore = new AppMenuStore();
  appMenuStore.getAll((gaRes) => {
    // console.log({ gaRes });
    let menuItems: SideBarMenuItem_I[] = [];

    // wireframeProvider.resetMenuItems();

    gaRes.forEach(menu => {
      // console.log({ "---menu---": menu });
      let menuItem: SideBarMenuItem_I = {
        active: __activePage(menu.id),
        baseUrl: menu.baseUrl,
        icon: menu.icon,
        listClass: "",
        title: menu.title,
      };

      if (!menuItems.includes(menuItem)) {
        menuItems.push(menuItem);
      }
    });
    // console.log({ "---menuItems---": menuItems });
    
    wireframeProvider.setMenuItems(menuItems);
    // console.log({ "---wireframeProvider.menuItems---": wireframeProvider.menuItems });
  });
}
export function wireframeAppMenuStore(wireframeProvider: WireframeProvider): void {
  // console.log({"-----1-2--3---4----5": wireframeProvider});
  setInterval(() => {
    if (wireframeProvider.menuItems.length <= 0) {
      // console.log({ "menu items": "loaded" });
      getAndSet(wireframeProvider);
    }
  }, 1000);

  return;
}
import { AppDatabase } from '..';
import { ApDbCallback_I } from '@@addons/interfaces/database';
import { AppMenus_I } from '@@addons/interfaces/database/app_menu';

export class AppMenuStore extends AppDatabase<AppMenus_I> {
  private db = this;
  public constructor() {
    super();

    this.storeTable = this.db.MenuItemsDBPosts__;
  }

  /**
   * formatRawData
   */
  public formatRawData(rawData: any) {
    // console.log({ rawData });
    const page = rawData['page']

    let tags: string[] = [];
    for (let i = 0; i < page['tags'].length; i++) {
      const tag = page['tags'][i];
      let name = tag.name;
      if (!tags.includes(name)) {
        tags.push(name);
      }
    }

    const proccessed: AppMenus_I = {
      id: Number(page['id']),
      baseUrl: String(page['url']),
      icon: String(page['icon']),
      tags: tags,
      title: String(page['name']),
      module: String(page['moduleId']['name']),
    };
    return proccessed;
  }

  /**
   * insert
   */
  public insert(callback?: ApDbCallback_I<string>) {
    // this.transaction('readwrite', this.storeTable, async () => {

    //   // Make sure we have something in DB:
    //   if ((await this.db.friend.where({ name: 'Josephine' }).count()) === 0) {
    //     const id = await this.storeTable.add({ name: "Josephine", age: 21 });
    //     alert(`Addded friend with id ${id}`);
    //   }

    //   callback()

    //   // Query:
    //   const youngFriends = await this.storeTable.where("age").below(25).toArray();

    //   // Show result:
    //   alert("My young pope: " + JSON.stringify(youngFriends));

    // }).catch(e => {
    //   alert(e.stack || e);
    // });
  }

}
import { MobxLitElement } from '@adobe/lit-mobx';
import { html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
// import "../../../assets/styles/views/wireframe/right_sidebar.scss";
import '../clock';
import "./right_drawer_content";

@customElement('right-side-bar')
export class RightSideBar extends MobxLitElement {

  @property({ type: Object })
  private _property?: any;

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() { }
  static styles = [
    css`
   :host { display: block; }
  `
  ];

  render() {
    return html`
      <akwaaba-app-clock></akwaaba-app-clock>
      <main class="right-drawer-content centered">
        <right-drawer-content></right-drawer-content>
      </main>
    `;
  }

  createRenderRoot() {
    return this;
  }
}

import { AppMenus_I } from "@@addons/interfaces/database/app_menu";


export const PublicAppPages: AppMenus_I[] = [
  {
    baseUrl: "questionnaires/",
    icon: "question_answer",
    id: 2,
    module: "Akwaaba Forms",
    title: "Questionnaires",
    tags: [
      "Questionnaires"
    ]
  },
  {
    baseUrl: "/view-profile",
    icon: "person",
    id: 3,
    module: "Akwaaba Forms",
    title: "My Profile",
    tags: [
      "Profile",
      "Account",
      "Update",
      "Edit",
    ]
  }
]

export const SystemAppPageCALENDAR: number = 99999999990,
  SystemAppPageNOTIFICATIONS: number = 99999999991,
  SystemAppPageOFFLINE_ACTIVITIES: number = 99999999992,
  SystemAppPageOUT_OFF_BOUNDS: number = 999999999999999;

export const SystemAppPages: AppMenus_I[] = [
  {
    baseUrl: "questionnaires/",
    icon: "question_answer",
    id: 2,
    module: "Akwaaba Forms",
    title: "Questionnaires",
    tags: [
      "Questionnaires"
    ]
  }
]
import { SideBarMenuItem_I } from '@@addons/interfaces/sidebar/menu-item';
import { AutoCompleteSearch_I } from '@@addons/interfaces/view/wireframe/search';
import { action, makeObservable, observable, toJS } from 'mobx';


// create a mobx observable
export class WireframeProvider {

  public isOnline: boolean = false;
  public setIsOnline(value: boolean) {
    this.isOnline = value;
  }

  public isSmallDevice: boolean = false;
  public setisSmallDevice(value: boolean) {
    this.isSmallDevice = value;
  }

  public isMediumDevice: boolean = false;
  public setisMediumDevice(value: boolean) {
    this.isMediumDevice = value;
  }

  public pageId: number = 0;
  public setPageId(value: number) {
    this.pageId = value;
  }

  public menuItems: SideBarMenuItem_I[] = [];
  public setMenuItems(value: SideBarMenuItem_I[]) {
    const __menuItems = toJS(this.menuItems);
    // console.log({"___value": value, "this.menuItems": __menuItems});
    value.forEach(val => {
      if (!__menuItems.includes(val)) {
        // console.log({"___val": val});
        this.menuItems.push(val);
      }
    });
    // console.log({"___this.menuItems": this.menuItems});
  }
  public resetMenuItems() {
    this.menuItems = [];
  }

  public autoCompleteSearch: AutoCompleteSearch_I[] = [];
  public setAutoCompleteSearch(value: AutoCompleteSearch_I[]) {
    // console.log({"___value": value});
    value.forEach(val => {
      if (!this.autoCompleteSearch.includes(val)) {
        this.autoCompleteSearch.push(val);
      }
    });
    // console.log({"___this.menuItems": this.menuItems});
  }

  public constructor() {
    makeObservable(this, {
      setisSmallDevice: action,
      isSmallDevice: observable,
      setisMediumDevice: action,
      isMediumDevice: observable,
      setPageId: action,
      pageId: observable,
      setMenuItems: action,
      resetMenuItems: action,
      menuItems: observable,
      setAutoCompleteSearch: action,
      autoCompleteSearch: observable,
      setIsOnline: action,
      isOnline: observable,
    });

  }
}

// create instance that can be shared across components
export let wireframeProvider = new WireframeProvider();
